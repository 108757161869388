import { Spinner } from '@kalecard/common';
import { useEffect, useState } from 'react';
import { BRAND } from '../../graphql/queries';
import { useQuery } from '@apollo/client';
import { Button } from '../catalyst/button';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import BrandDetails, {
  isBrandDetailsStepComplete,
} from '../brands/setup/BrandDetails';
import BrandAssets, {
  isBrandAssetsStepComplete,
} from '../brands/setup/BrandAssets';
import BrandSettings, {
  isBrandSettingsStepComplete,
} from '../brands/setup/BrandSettings';
import BrandAdmins, {
  isBrandAdminsStepComplete,
} from '../brands/setup/BrandAdmins';
import BrandContentInfo from '../brands/setup/BrandContentInfo';
import BrandInstagramInfo from '../brands/setup/BrandInstagramInfo';
import BrandAvailability from '../brands/setup/BrandAvailability';
import { useHistory, useParams } from 'react-router-dom';
import BrandCreatorPayments, {
  isBrandCreatorPaymentsStepComplete,
} from '../brands/setup/BrandCreatorPayments';
import BrandInvoicingInfo, {
  isBrandInvoicingStepComplete,
} from '../brands/setup/BrandInvoicingInfo';
import { Brand } from '../../__generated__/graphql';

interface RouteParams {
  id: string;
  step?: string;
}

export default function UpdateBrandForm({ brandId }: { brandId: string }) {
  const steps = [
    'details',
    'assets',
    'settings',
    'availability',
    'creator payments',
    'invoicing',
    'admins',
    'content',
    'instagram',
  ];

  const { step } = useParams<RouteParams>();
  const history = useHistory();
  const [currentStep, setCurrentStep] = useState(step || 'details');

  useEffect(() => {
    // Default to details step
    if (!steps.includes(step)) {
      history.push(`/brands/${brandId}/setup/details`);
    } else {
      setCurrentStep(step);
    }
  }, [step, brandId, history]);

  interface BrandData {
    brand: Brand;
  }
  const { data, loading } = useQuery<BrandData>(BRAND, {
    variables: {
      id: brandId,
    },
  });

  const setNextStep = () => {
    const index = steps.findIndex((s) => s === currentStep);
    if (index < steps.length - 1) {
      history.push(`/brands/${brandId}/setup/${steps[index + 1]}`);
    }
  };

  const setPreviousStep = () => {
    const index = steps.findIndex((step) => step === currentStep);
    if (index > 0) {
      history.push(`/brands/${brandId}/setup/${steps[index - 1]}`);
    }
  };

  const getStepForm = (step: string, brand: Brand) => {
    switch (step) {
      case 'details':
        return (
          <BrandDetails
            brand={brand}
            isCreationFlow={false}
          />
        );
      case 'assets':
        return <BrandAssets brand={brand} />;
      case 'settings':
        return <BrandSettings brand={brand} />;
      case 'availability':
        return <BrandAvailability brand={brand} />;
      case 'creator payments':
        return <BrandCreatorPayments brand={brand} />;
      case 'invoicing':
        return <BrandInvoicingInfo brand={brand} />;
      case 'admins':
        return <BrandAdmins brand={brand} />;
      case 'content':
        return <BrandContentInfo brand={brand} />;
      case 'instagram':
        return <BrandInstagramInfo brand={brand} />;
    }
  };

  const getStepComplete = (step: string, brand: Brand) => {
    switch (step) {
      case 'details':
        return isBrandDetailsStepComplete(brand);
      case 'assets':
        return isBrandAssetsStepComplete(brand);
      case 'settings':
        return isBrandSettingsStepComplete(brand);
      case 'availability':
        return true;
      case 'creator payments':
        return isBrandCreatorPaymentsStepComplete(brand);
      case 'invoicing':
        return isBrandInvoicingStepComplete(brand);
      case 'admins':
        return isBrandAdminsStepComplete(brand);
      case 'content':
        return true;
      case 'instagram':
        return true;
    }
  };

  return !loading && data ? (
    <div className="space-y-4">
      {/* Steps progress bar */}
      <div className="flex flex-row justify-center">
        {steps.map((step, index) => {
          const isCurrentStep = step === currentStep;
          const isComplete = getStepComplete(step, data.brand);

          return (
            <>
              {index !== 0 && (
                <div
                  key={'line' + index}
                  className="mt-5 h-0.5 w-full bg-gray-300"
                ></div>
              )}
              <div
                key={'step' + index}
                className="flex flex-col items-center space-y-1"
                onClick={() => history.push(`/brands/${brandId}/setup/${step}`)}
              >
                <div
                  className={`flex h-10 w-10 items-center justify-center rounded-full bg-gray-200 text-xs hover:cursor-pointer ${
                    isCurrentStep
                      ? 'bg-kale-mint-500 font-medium'
                      : 'bg-gray-200 hover:border hover:border-gray-500'
                  }`}
                >
                  {index + 1}
                </div>
                <div className="flex items-center space-x-1">
                  <div
                    className={`text-center text-sm capitalize ${
                      isCurrentStep ? 'font-medium' : ''
                    }`}
                  >
                    {step}
                  </div>
                  {!isComplete && (
                    <ExclamationCircleIcon
                      color="orange"
                      className="h-6 w-6"
                    />
                  )}
                </div>
              </div>
            </>
          );
        })}
      </div>

      {/* Data */}
      <div
        key={step + 'form'}
        className="w-full rounded-md border border-gray-200 p-4"
      >
        {getStepForm(currentStep, data.brand)}
      </div>

      <div className="flex flex-row justify-center space-x-4">
        {currentStep !== steps[0] && (
          <Button
            color="light"
            onClick={setPreviousStep}
          >
            Previous
          </Button>
        )}
        {currentStep !== steps[steps.length - 1] && (
          <Button
            color="emerald"
            onClick={setNextStep}
          >
            Next
          </Button>
        )}
      </div>
    </div>
  ) : (
    <div className="flex flex-wrap content-center justify-center pr-2">
      <Spinner size="h-6 w-6" />
    </div>
  );
}
