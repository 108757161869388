import { useMutation } from '@apollo/client';
import {
  ArrowPathIcon,
  BuildingStorefrontIcon,
} from '@heroicons/react/24/solid';
import {
  Modal,
  numberWithCommas,
  timeDifferenceForDate,
  formatPercentage,
} from '@kalecard/common';
import { Challenge } from '../../__generated__/graphql';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  TOGGLE_CHALLENGE_STATE,
  UPDATE_CHALLENGE_RANK,
} from '../../graphql/mutations';
import EditChallengeForm from '../forms/EditChallengeForm';
import { ChallengesContext } from '../../providers/ChallengesProvider';
import { ChallengesActionType } from '../../reducers/ChallengesReducer';
import {
  ChatBubbleLeftEllipsisIcon,
  ClockIcon,
  GiftIcon,
  LinkIcon,
  ListBulletIcon,
  VideoCameraIcon,
} from '@heroicons/react/24/outline';
import ChallengeFeedbackForm from '../forms/ChallengeFeedbackForm';
import { classNames } from '../../utils/style';
import ChallengeHistory from './ChallengeHistory';
import { ChallengeState } from './ChallengeState';
import { Button } from '../catalyst/button';
import { ToolTip } from '../ToolTip';
import { Badge } from '../catalyst/badge';

type ChallengeRowInput = {
  belowChallengeId?: string;
};

export default function ChallengeRow({
  challenge,
  belowChallengeId,
  isNewChallenge,
}: {
  challenge: Challenge;
  belowChallengeId?: string;
  isNewChallenge: boolean;
}) {
  const {
    state: { challenges, newChallenges, sortingType },
    dispatch: challengesDispatch,
  } = useContext(ChallengesContext);

  const [toggling, setToggling] = useState(false);
  const [isEditChallengeModalOpen, setIsEditChallengeModalOpen] =
    useState(false);
  const [isRankInvalid, setIsRankInvalid] = useState(false);
  const { register, setValue, getValues } = useForm<ChallengeRowInput>({
    defaultValues: {
      belowChallengeId: belowChallengeId,
    },
  });
  const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false);

  const [toggleChallenge] = useMutation(TOGGLE_CHALLENGE_STATE);
  const [updateChallengeRank] = useMutation(UPDATE_CHALLENGE_RANK);

  const [isChallengeFeedbackModalOpen, setIsChallengeFeedbackModalOpen] =
    useState(false);

  const handleModalSubmit = (updatedChallenge: Challenge) => {
    if (challenge.state === 'PENDING_REVIEW') {
      challengesDispatch({
        type: ChallengesActionType.REVIEW_PENDING_REVIEW_CHALLENGE,
      });
    }
    challengesDispatch({
      type: ChallengesActionType.UPDATE_CHALLENGE,
      payload: updatedChallenge,
    });
    setIsEditChallengeModalOpen(false);
  };

  const toggle = async () => {
    setToggling(true);
    try {
      const result = await toggleChallenge({
        variables: {
          challengeId: challenge.id,
        },
      });
      if (result.data.toggleChallengeState) {
        challengesDispatch({
          type: ChallengesActionType.TOGGLE_CHALLENGE_STATE,
          payload: challenge,
        });
      }
    } catch (err) {
      console.error(err);
    }

    setToggling(false);
  };

  const addCommercialSoundsOnlyFilter = () => {
    challengesDispatch({
      type: ChallengesActionType.TOGGLE_COMMERCIAL_SOUNDS_ONLY_FILTER,
      payload: true,
    });
  };

  const handleKeyDownBelowChallengeId = async (event) => {
    if (event.key !== 'Enter') return;
    const newBelowChallengeId = getValues('belowChallengeId') || null;
    try {
      const result = await updateChallengeRank({
        variables: {
          challengeId: challenge.id,
          belowChallengeId: newBelowChallengeId,
        },
      });
      console.log(result);
      if (result.data.updateChallengeRank) {
        challengesDispatch({
          type: ChallengesActionType.RERANK_CHALLENGE,
          payload: {
            challenge,
            newBelowChallengeId,
          },
        });
        setIsRankInvalid(false);
      } else {
        setIsRankInvalid(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const updatedChallenge = (isNewChallenge ? newChallenges : challenges).find(
    (tempChallenge) => tempChallenge.id === challenge.id
  );

  const newFeedback = challenge.feedback?.filter(
    (feedback) => feedback.status === 'NEW'
  );

  useEffect(() => {
    setValue('belowChallengeId', belowChallengeId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [belowChallengeId]);

  return (
    <>
      {challenge && (
        <tr>
          {sortingType?.id === 'RANK_ASC' && (
            <td className="whitespace-nowrap px-4 py-4 text-sm">
              <div className="flex items-center">
                {updatedChallenge?.state === 'ACTIVE' && !isNewChallenge && (
                  <input
                    {...register('belowChallengeId')}
                    name="belowChallengeId"
                    id="belowChallengeId"
                    className={`w-24 rounded-md border border-gray-300 bg-white py-2 pl-3 pr-3 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm ${
                      isRankInvalid ? 'text-red-500' : ''
                    }`}
                    onKeyDown={handleKeyDownBelowChallengeId}
                  />
                )}
              </div>
            </td>
          )}
          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm">
            <div className="flex items-center">
              <div className="h-10 w-10 flex-shrink-0">
                {challenge?.brand?.logoUrl ? (
                  <img
                    className="h-10 w-10 rounded-full"
                    src={challenge?.brand?.logoUrl}
                    alt=""
                  />
                ) : (
                  <BuildingStorefrontIcon className="h-12 w-12 rounded-full border p-1" />
                )}
              </div>
              <div className="ml-4">
                <div className="font-medium text-gray-900">
                  {challenge?.brand?.name}
                </div>
                <div className="text-xs text-gray-500">
                  <p>
                    Created {timeDifferenceForDate(Number(challenge.createdAt))}
                  </p>
                  <p>ID: {challenge.id}</p>
                  <div className="flex flex-row space-x-1 pt-2">
                    <ToolTip message={'View posts'}>
                      <Button
                        outline
                        href={`/posts?challengeId=${challenge.id}`}
                        target="_blank"
                      >
                        <VideoCameraIcon />
                      </Button>
                    </ToolTip>
                    <ToolTip message={'View history'}>
                      <Button
                        outline
                        className="hover:cursor-pointer"
                        onClick={() => setIsHistoryModalOpen(true)}
                      >
                        <ClockIcon />
                      </Button>
                    </ToolTip>
                    {challenge.exampleUrl && (
                      <ToolTip message={'View example'}>
                        <Button
                          outline
                          href={challenge.exampleUrl}
                          target="_blank"
                        >
                          <LinkIcon />
                        </Button>
                      </ToolTip>
                    )}
                    {challenge.requiredBrandProducts?.length > 0 && (
                      <Button
                        outline
                        href={challenge.requiredBrandProducts[0].externalUrl}
                        target="_blank"
                      >
                        <GiftIcon />
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </td>
          <td className="whitespace-pre-wrap px-3 py-4 text-sm text-gray-500">
            <div className="font-medium text-gray-900">{challenge.title}</div>
            {challenge.state === 'PENDING_REVIEW' &&
              challenge.challengeTemplate && (
                <div className="flex pt-1 text-center">
                  <Badge
                    className="capitalize"
                    color="cyan"
                  >
                    {challenge.challengeTemplate.templateType?.toLowerCase()}
                  </Badge>
                </div>
              )}
            <div className="text-gray-900">{challenge.description}</div>
            <div className="text-gray-500">{challenge.tagRequirements}</div>
            <div className="flex items-center space-x-2">
              <div className="">
                {Array.from({ length: challenge.difficulty ?? 0 }, (_, i) => (
                  <span
                    className="text-base"
                    key={i}
                  >
                    {' '}
                    🥬{' '}
                  </span>
                ))}
              </div>
              {challenge.isCommercialSoundsOnly === true && (
                <div
                  className="my-2 inline-flex w-fit items-center rounded-full bg-kale-orange-200 px-4 py-1 text-xs font-medium text-gray-900 hover:cursor-pointer"
                  onClick={addCommercialSoundsOnlyFilter}
                >
                  Commercial Sounds Only
                </div>
              )}
            </div>
            <div className="text-xs">
              {(challenge.startDate || challenge.endDate) && (
                <div className="py-1 font-medium">
                  <p>Active Period:</p>
                  <p>
                    {challenge.startDate
                      ? new Date(
                          Number(challenge.startDate)
                        ).toLocaleDateString('en-us', {
                          hourCycle: 'h12',
                          hour: '2-digit',
                          minute: '2-digit',
                        })
                      : '?'}
                    {' --> '}
                    {challenge.endDate
                      ? new Date(Number(challenge.endDate)).toLocaleDateString(
                          'en-us',
                          {
                            hourCycle: 'h12',
                            hour: '2-digit',
                            minute: '2-digit',
                          }
                        )
                      : '?'}
                  </p>
                </div>
              )}
            </div>
          </td>
          <td className="flex items-center whitespace-nowrap px-3 py-4 text-sm text-gray-500">
            <ChallengeState
              state={challenge.state}
              source={challenge.source}
              setIsEditChallengeModalOpen={setIsEditChallengeModalOpen}
            />
            <div className="ml-2">
              {(challenge.state === 'ACTIVE' ||
                challenge.state === 'INACTIVE') && (
                <button
                  onClick={toggle}
                  className="rounded-full bg-kale-mint-500 p-1 opacity-70 hover:opacity-100"
                >
                  <ArrowPathIcon
                    className={`h-4 w-4 text-kale-green-500 ${
                      toggling ? 'animate-spin' : ''
                    }`}
                  />
                </button>
              )}
            </div>
          </td>
          <td className="whitespace-pre-wrap px-3 py-4 text-center text-sm">
            <div className="text-gray-900">
              {numberWithCommas(challenge.totalClaims)}
            </div>
          </td>
          <td className="whitespace-pre-wrap px-3 py-4 text-center text-sm">
            <div className="text-gray-900">
              {numberWithCommas(challenge.totalPosts)}
            </div>
          </td>
          <td className="whitespace-pre-wrap px-3 py-4 text-center text-sm">
            <div className="text-gray-900">
              {formatPercentage(challenge.postRate)}
            </div>
          </td>
          {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
            {challenge.audienceType === "ALL" ? (
              <p>All users</p>
            ) : (
              <>
                <p>Targeted by:</p>
                <div className="flex flex-wrap">
                  {challenge?.audience?.brands &&
                    Array.from(challenge?.audience?.brands).map((value) => {
                      return <AudiencePill key={value.id} audience={value} />;
                    })}
                  {challenge?.audience?.brandCategories &&
                    Array.from(challenge?.audience?.brandCategories).map(
                      (value) => {
                        return <AudiencePill key={value.id} audience={value} />;
                      }
                    )}
                  {challenge?.audience?.creators &&
                    Array.from(challenge?.audience?.creators).map((value) => {
                      return (
                        <AudiencePill
                          key={value.id}
                          audience={{ id: value.id, name: value.id }}
                        />
                      );
                    })}
                </div>
              </>
            )}
          </td> */}
          <td className="px-2">
            {(challenge.state !== 'REJECTED' ||
              challenge.source === 'KALE_ADMIN') &&
              challenge.state !== 'DELETED' && (
                <div className="flex">
                  {challenge.state !== 'REJECTED' && (
                    <button
                      type="button"
                      onClick={() => {
                        setIsEditChallengeModalOpen(true);
                      }}
                      className="inline-flex items-center justify-center rounded-md border border-indigo-600 border-transparent bg-white px-4 py-2 text-sm font-medium text-indigo-600 shadow-sm hover:outline-none hover:ring-2 hover:ring-indigo-500 hover:ring-offset-2 sm:w-auto"
                    >
                      {challenge.state === 'PENDING_REVIEW' &&
                      challenge.source === 'BRAND_ADMIN'
                        ? 'Review'
                        : 'Edit'}
                    </button>
                  )}
                  {newFeedback?.length > 0 && (
                    <div
                      className="flex items-center space-x-1 pl-2 hover:cursor-pointer"
                      onClick={() => setIsChallengeFeedbackModalOpen(true)}
                    >
                      <ChatBubbleLeftEllipsisIcon className="h-5 w-5 text-indigo-600" />
                    </div>
                  )}
                </div>
              )}
            <Modal
              isModalOpen={isEditChallengeModalOpen}
              onClose={() => setIsEditChallengeModalOpen(false)}
              className="w-auto"
            >
              <div className="flex w-full space-x-4 divide-x">
                <div
                  className={classNames(
                    challenge.feedback?.length > 0 ? 'w-2/3' : 'w-full'
                  )}
                >
                  <EditChallengeForm
                    challenge={challenge as Challenge}
                    onSubmit={handleModalSubmit}
                  />
                </div>
                {challenge.feedback?.length > 0 && (
                  <div className="w-1/3 pl-4">
                    <ChallengeFeedbackForm challenge={challenge as Challenge} />
                  </div>
                )}
              </div>
            </Modal>
            <Modal
              isModalOpen={
                isChallengeFeedbackModalOpen && challenge.feedback?.length > 0
              }
              onClose={() => setIsChallengeFeedbackModalOpen(false)}
              className=""
            >
              <ChallengeFeedbackForm challenge={challenge as Challenge} />
            </Modal>
            <Modal
              isModalOpen={isHistoryModalOpen}
              onClose={() => setIsHistoryModalOpen(false)}
              className="max-h-screen w-2/3 px-10"
            >
              <ChallengeHistory challenge={challenge as Challenge} />
            </Modal>
          </td>
        </tr>
      )}
    </>
  );
}
