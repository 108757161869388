import { FaceSmileIcon } from '@heroicons/react/24/outline';
import { Heading } from '../catalyst/heading';
import { Field, Fieldset, Label } from '../catalyst/fieldset';
import { useEmojiPickerForInput } from '../../utils/emojiPicker';
import { useRef, useState } from 'react';
import { Input } from '../catalyst/input';
import { Button } from '../catalyst/button';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { UPDATE_CHALLENGE_TEMPLATE } from '../../graphql/mutations';
import { ChallengeTemplate, Spinner } from '@kalecard/common';
import { CHALLENGE_TEMPLATES } from '../../graphql/queries';

interface UpdateChallengeTemplateInput {
  title: string;
  soundUrl?: string;
  exampleUrl?: string;
  template?: string;
}

export default function EditChallengeTemplateForm({
  challengeTemplate,
  onSubmit,
}: {
  challengeTemplate: ChallengeTemplate;
  onSubmit: () => void;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const { register, handleSubmit, setValue, getValues } =
    useForm<UpdateChallengeTemplateInput>({
      defaultValues: {
        ...challengeTemplate,
      },
    });
  const [updateChallengeTemplate] = useMutation(UPDATE_CHALLENGE_TEMPLATE);
  const [error, setError] = useState(null);

  const submit: SubmitHandler<UpdateChallengeTemplateInput> = async (
    data: UpdateChallengeTemplateInput
  ) => {
    setIsLoading(true);
    try {
      const result = await updateChallengeTemplate({
        variables: {
          challengeTemplateId: challengeTemplate.id,
          title: data.title,
          soundUrl: data.soundUrl,
          exampleUrl: data.exampleUrl,
        },
        refetchQueries: [CHALLENGE_TEMPLATES],
      });
      console.log(result);
      setError(null);
      onSubmit();
    } catch (err) {
      console.log(err);
      setError(err);
    }
    setIsLoading(false);
  };

  const onDelete = async () => {
    setIsLoading(true);
    try {
      const result = await updateChallengeTemplate({
        variables: {
          challengeTemplateId: challengeTemplate.id,
          status: 'DELETED',
        },
        refetchQueries: [CHALLENGE_TEMPLATES],
      });
      console.log(result);
      setError(null);
      onSubmit();
    } catch (err) {
      console.log(err);
      setError(err);
    }
    setIsLoading(false);
  };

  return (
    <form className="space-y-4">
      {/* Header */}
      <div className="text-center">
        <Heading>{challengeTemplate.title}</Heading>
      </div>
      <Fieldset className="space-y-4">
        {/* Title */}
        <ChallengeTemplateTitle
          register={register}
          setValue={setValue}
          getValues={getValues}
        />

        {/* Example Video */}
        <Field>
          <Label>Example Video</Label>
          <Input
            type="text"
            {...register('exampleUrl', {
              required: challengeTemplate.templateType === 'TREND',
            })}
          ></Input>
        </Field>

        {/* Example Video */}
        <Field>
          <Label>Sound Link</Label>
          <Input
            type="text"
            {...register('soundUrl')}
          ></Input>
        </Field>
      </Fieldset>

      <div className="flex items-center justify-between space-x-4">
        <Button
          color="red"
          onClick={onDelete}
        >
          Delete
        </Button>
        {isLoading && <Spinner size="h-5 w-5" />}
        <Button
          color="emerald"
          onClick={handleSubmit(submit)}
        >
          Update
        </Button>
      </div>
      {error && <p className="text-right text-sm text-red-500">{error}</p>}
    </form>
  );
}

function ChallengeTemplateTitle({ register, setValue, getValues }) {
  const [isEmojiOpen, setIsEmojiOpen] = useState<boolean>(false);
  const pickerRef = useRef(null);
  const inputRef = useRef(null);
  const inputName = 'title';
  const querySelector = 'input[type="text"]';

  const { addEmojiAtCursor, handleCursorChange } = useEmojiPickerForInput({
    setValue,
    getValues,
    inputName,
    querySelector,
    pickerRef,
    inputRef,
    setIsEmojiOpen,
  });

  return (
    <Field className="space-y-2">
      <Label>Trend Title</Label>
      <div
        className="flex space-x-2"
        ref={inputRef}
      >
        <Input
          maxLength={26}
          type="text"
          {...register(inputName, { required: true })}
          onClick={handleCursorChange}
          onChange={handleCursorChange}
        />
        <Button
          type="button"
          plain
          className="hover:cursor-pointer"
          onClick={() => setIsEmojiOpen(!isEmojiOpen)}
        >
          <FaceSmileIcon />
        </Button>
      </div>
      {isEmojiOpen && (
        <div className="relative flex">
          <div
            className="absolute left-0 top-0 z-10"
            ref={pickerRef}
          >
            <Picker
              data={data}
              onEmojiSelect={(emoji) => {
                addEmojiAtCursor(emoji);
              }}
              autoFocus={true}
            />
          </div>
        </div>
      )}
    </Field>
  );
}
