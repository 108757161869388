import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { POST_REQUIREMENTS, SEARCH_REQUIREMENTS } from '../../graphql/queries';
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from '../catalyst/table';
import {
  FaceSmileIcon,
  PencilSquareIcon,
  XCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import Picker from '@emoji-mart/react';
import data from '@emoji-mart/data';
import { useEffect, useState } from 'react';
import { Checkbox } from '../catalyst/checkbox';
import { Combobox } from '@headlessui/react';
import { CREATE_POST_REQUIREMENT } from '../../graphql/mutations';
import { ChallengePreview } from '../challenges/ChallengePreview';
import {
  Brand,
  BrandPostRequirementBreakdown,
  Challenge,
  PostRequirement,
} from '../../__generated__/graphql';
import { InstagramLogo, TiktokLogo } from '@kalecard/common';
import { Input } from '../catalyst/input';
import { Button } from '../catalyst/button';
import { Badge } from '../catalyst/badge';
import { Text } from '../catalyst/text';
import { Badge as FlowbiteBadge } from 'flowbite-react';

export default function RequirementsTable({
  allowCreating = true,
  allowEditing = false,
  showSearch = true,
  showPreview = true,
  currentActiveRequirements,
  currentBreakdownRequirements = null,
  challenge,
  hiddenTypes = new Set(),
  onAddRequirement,
  onRemoveRequirement,
  showBreakdown = false,
  showLoading = false,
}: {
  allowCreating?: boolean;
  allowEditing?: boolean;
  showSearch?: boolean;
  showPreview?: boolean;
  brandId?: string;
  currentActiveRequirements?: PostRequirement[];
  currentBreakdownRequirements?: BrandPostRequirementBreakdown;
  challenge?: Challenge;
  hiddenTypes?: Set<string>;
  onAddRequirement: (postRequirement: PostRequirement) => void;
  onRemoveRequirement?: (postRequirement: PostRequirement) => void;
  showBreakdown?: boolean;
  showLoading?: boolean;
}) {
  return (
    <div className={showPreview ? 'flex w-full justify-center' : ''}>
      {showLoading ? (
        <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75">
          <div className="loader">Loading...</div>
        </div>
      ) : null}
      {showPreview ? (
        <div className="mr-3 flex w-2/5 flex-col space-y-2 pt-2">
          <ChallengePreview
            currentRequirements={
              currentActiveRequirements ?? challenge?.postRequirements
            }
            brandLogoUrl={challenge?.brand?.logoUrl}
            brandName={challenge?.brand?.name}
            exampleUrl={challenge?.exampleUrl}
            requiredBrandProducts={challenge?.requiredBrandProducts}
            title={challenge?.title}
            difficulty={challenge?.difficulty}
            description={challenge?.description}
          />
        </div>
      ) : null}
      <div className="flex-col">
        {showSearch ? (
          <SearchRequirement
            text="Search existing requirements"
            onSearch={(query) => {
              // Handle search
            }}
            onAdd={(postRequirement: PostRequirement) => {
              if (onAddRequirement) {
                onAddRequirement(postRequirement);
              }
            }}
          />
        ) : null}
        <Table className="h-full min-w-full divide-y divide-gray-300 overflow-x-visible">
          <TableHead>
            <TableRow>
              <TableCell className="py-2 pl-4 text-left text-sm font-semibold text-gray-900">
                Type
              </TableCell>
              <TableCell className="py-2 pl-4 text-left text-sm font-semibold text-gray-900">
                Emoji
              </TableCell>
              <TableCell className="py-2 pl-4 pr-4 text-left text-sm font-semibold text-gray-900">
                Requirement
              </TableCell>
              <TableCell className="py-2 pl-2 pr-2 text-left text-sm font-semibold text-gray-900">
                {showBreakdown ? 'Applied/Total' : 'Kale Default'}
              </TableCell>
              <TableCell className="py-2 pl-4 pr-4 text-left text-sm font-semibold text-gray-900">
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="m-4 bg-white">
            <>
              {allowCreating ? (
                <NewRequirement
                  handleNewRequirement={(postRequirement) => {
                    onAddRequirement(postRequirement);
                  }}
                />
              ) : null}
              {showBreakdown && currentBreakdownRequirements != null
                ? currentBreakdownRequirements?.postRequirementsByChallengeCount
                    .filter(
                      (postReqByChallenge) =>
                        !hiddenTypes.has(
                          postReqByChallenge.postRequirement.type
                        )
                    )
                    .map((postRequirementByChallenge, idx: number) => (
                      <RequirementRow
                        key={postRequirementByChallenge.postRequirement.id}
                        id={postRequirementByChallenge.postRequirement.id}
                        type={postRequirementByChallenge.postRequirement.type}
                        emoji={postRequirementByChallenge.postRequirement.emoji}
                        requirement={
                          postRequirementByChallenge.postRequirement.requirement
                        }
                        isDefault={
                          postRequirementByChallenge.postRequirement.isDefault
                        }
                        externalPlatform={
                          postRequirementByChallenge.postRequirement
                            .externalPlatform
                        }
                        referenceBrand={
                          postRequirementByChallenge.postRequirement
                            .referenceBrand
                        }
                        idx={idx}
                        allowEditing={allowEditing}
                        onAdd={() => {
                          if (onAddRequirement) {
                            onAddRequirement(
                              postRequirementByChallenge.postRequirement
                            );
                          }
                        }}
                        onRemove={() => {
                          if (onRemoveRequirement) {
                            onRemoveRequirement(
                              postRequirementByChallenge.postRequirement
                            );
                          }
                        }}
                        breakdownCount={
                          postRequirementByChallenge.challengeCount
                        }
                        totalCount={
                          currentBreakdownRequirements.totalChallenges
                        }
                        showBreakdown={true}
                      />
                    ))
                : currentActiveRequirements
                    .filter((req) => !hiddenTypes.has(req.type))
                    .map((requirement: PostRequirement, idx: number) => (
                      <RequirementRow
                        key={requirement.id}
                        id={requirement.id}
                        type={requirement.type}
                        emoji={requirement.emoji}
                        requirement={requirement.requirement}
                        isDefault={requirement.isDefault}
                        externalPlatform={requirement.externalPlatform}
                        referenceBrand={requirement?.referenceBrand}
                        idx={idx}
                        allowEditing={allowEditing}
                        onAdd={() => {
                          if (onAddRequirement) {
                            onAddRequirement(requirement);
                          }
                        }}
                        onRemove={() => {
                          if (onRemoveRequirement) {
                            onRemoveRequirement(requirement);
                          }
                        }}
                        showBreakdown={false}
                      />
                    ))}
            </>
          </TableBody>
        </Table>
      </div>
    </div>
  );
}

const SearchRequirement = ({
  text,
  onSearch,
  onAdd,
}: {
  text: string;
  onSearch: (query: string) => void;
  onAdd(postRequirement: PostRequirement): void;
}) => {
  const [searchRequirements, { data: searchData }] =
    useLazyQuery(SEARCH_REQUIREMENTS);
  const [query, setQuery] = useState('');
  const [selectedRequirement, setSelectedRequirement] = useState(null);

  useEffect(() => {
    if (query.length > 0) {
      searchRequirements({
        variables: { query },
      });
    }
  }, [query]);

  return (
    <div className="flex flex-row items-center">
      <div className="flex w-full items-center justify-between p-4">
        <div className="flex w-full items-center">
          <Combobox
            value={selectedRequirement}
            onChange={setSelectedRequirement}
          >
            <div className="relative w-full">
              <Combobox.Input
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                onChange={(event) => setQuery(event.target.value)}
                displayValue={(requirement: PostRequirement) =>
                  requirement?.type == 'MENTION'
                    ? 'Mention ' +
                      requirementForMention(
                        requirement?.referenceBrand,
                        requirement?.externalPlatform
                      )
                    : requirement?.requirement
                }
                placeholder={text}
              />
              <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {searchData?.searchRequirements.length === 0 && query !== '' ? (
                  <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                    No requirements found.
                  </div>
                ) : (
                  searchData?.searchRequirements.map((requirement: any) => (
                    <Combobox.Option
                      key={requirement.id}
                      className={({ active }) =>
                        `relative cursor-default select-none py-2 pl-10 pr-4 ${
                          active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                        }`
                      }
                      value={requirement}
                    >
                      {({ selected, active }) =>
                        RequirementSearchItem(requirement, selected)
                      }
                    </Combobox.Option>
                  ))
                )}
              </Combobox.Options>
            </div>
          </Combobox>
        </div>
      </div>
      <Button
        color="indigo"
        disabled={!selectedRequirement}
        onClick={() => {
          if (selectedRequirement) {
            onAdd(selectedRequirement);
            setQuery('');
            setSelectedRequirement(null);
          }
        }}
      >
        Add
      </Button>
    </div>
  );
};

const RequirementSearchItem = (
  requirement: PostRequirement,
  selected: boolean
) => (
  <>
    {requirement.type && (
      <div className="inline-block">
        <TypeBadge type={requirement.type} />
      </div>
    )}
    {requirement.emoji && (
      <span className="absolute inset-y-0 left-0 flex items-center pl-3">
        {requirement.emoji}
      </span>
    )}
    <span
      className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}
    >
      {requirement.type == 'MENTION'
        ? `${requirementForMention(
            requirement.referenceBrand,
            requirement.externalPlatform
          )}`
        : requirement.requirement}
    </span>
  </>
);

const NEW_REQUIREMENT_TOGGLE = ['CONTENT', 'SOUND', 'HASHTAG'];

const NewRequirement = ({
  handleNewRequirement,
}: {
  handleNewRequirement: (postRequirement: any) => void;
}) => {
  const [createPostRequirement] = useMutation(CREATE_POST_REQUIREMENT, {
    refetchQueries: [POST_REQUIREMENTS],
  });

  const [isEmojiOpen, setIsEmojiOpen] = useState(false);
  const [emoji, setEmoji] = useState(null);

  const [requirementText, setRequirementText] = useState('');

  const [selectedType, setSelectedType] = useState('CONTENT');
  const [selectedTypeIndex, setSelectedTypeIndex] = useState(0);

  useEffect(() => {
    setEmoji(null);
    setRequirementText('');
    setSelectedType(NEW_REQUIREMENT_TOGGLE[selectedTypeIndex]);
  }, [selectedTypeIndex]);

  return (
    <TableRow>
      <TableCell className="w-24">
        <button
          type="button"
          className="border-1 ml-2 inline-block rounded-md p-1 outline-dashed"
          onClick={() => {
            setSelectedTypeIndex(
              (selectedTypeIndex + 1) % NEW_REQUIREMENT_TOGGLE.length
            );
          }}
        >
          <TypeBadge type={selectedType} />
        </button>
      </TableCell>
      <TableCell className="whitespace-nowrap py-4 pl-3 text-sm">
        <div className="flex space-x-2">
          {selectedType != 'HASHTAG' ? (
            <div>
              <button
                type="button"
                onClick={() => {
                  setIsEmojiOpen(!isEmojiOpen);
                }}
              >
                {emoji ? (
                  <p className="text-lg">{emoji}</p>
                ) : (
                  <FaceSmileIcon className="h-5 w-5" />
                )}
              </button>
            </div>
          ) : null}

          {isEmojiOpen && (
            <div className="absolute z-10">
              <Picker
                data={data}
                onEmojiSelect={(emoji) => {
                  setEmoji(emoji.native);
                  setIsEmojiOpen(false);
                }}
              />
            </div>
          )}
        </div>
      </TableCell>
      <TableCell className="whitespace-nowrap text-sm">
        <Input
          type="text"
          name="requirement"
          id="requirement"
          placeholder="Requirement"
          value={requirementText}
          onChange={(e) => setRequirementText(e.target.value)}
        />
      </TableCell>
      <TableCell>
        {/* This is for Kale Default but users cannot edit this */}
      </TableCell>
      <TableCell className="whitespace-nowrap text-sm font-medium">
        <div className="flex space-x-2 text-right">
          <Button
            color="indigo"
            disabled={
              requirementText?.length == 0 ||
              (selectedType == 'HASHTAG' && !requirementText.startsWith('#'))
            }
            onClick={async () => {
              try {
                const result = await createPostRequirement({
                  variables: {
                    type: selectedType,
                    requirement: requirementText,
                    emoji: emoji,
                  },
                });
                console.log(result);
                const newRequirement = result?.data?.createPostRequirement;
                handleNewRequirement(newRequirement);
                setRequirementText('');
                setEmoji(null);
                setSelectedTypeIndex(0);
              } catch (err) {
                console.log(err);
              }
            }}
          >
            Create
          </Button>
        </div>
      </TableCell>
    </TableRow>
  );
};

const RequirementRow = ({
  id,
  type,
  emoji,
  requirement,
  isDefault,
  externalPlatform,
  referenceBrand,
  idx,
  allowEditing,
  onAdd,
  onRemove,
  breakdownCount = null,
  totalCount = null,
  showBreakdown,
}: {
  id: string;
  type: string;
  emoji: string;
  requirement: string;
  isDefault: boolean;
  externalPlatform: string;
  referenceBrand: Brand;
  idx: number;
  allowEditing: boolean;
  onAdd: () => void;
  onRemove: () => void;
  breakdownCount?: number;
  totalCount?: number;
  showBreakdown: boolean;
}) => {
  return (
    <TableRow className={idx % 2 === 0 ? undefined : 'bg-gray-50'}>
      <TableCell className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900">
        <div className="ml-2 inline-block">
          <TypeBadge type={type ?? 'CONTENT'} />
        </div>
      </TableCell>
      <TableCell className="whitespace-nowrap py-4 pl-3 pr-3 text-lg font-medium text-gray-900">
        {type == 'MENTION' ? emojiForMention(externalPlatform) : emoji}
      </TableCell>
      <TableCell className="text-wrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900">
        {type == 'MENTION'
          ? requirementForMention(referenceBrand, externalPlatform)
          : requirement}
      </TableCell>
      <TableCell className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900">
        {showBreakdown ? (
          <Badge color={breakdownCount < totalCount ? 'fuchsia' : 'green'}>
            <Text>
              {breakdownCount}/{totalCount}
            </Text>
          </Badge>
        ) : (
          <Checkbox
            checked={isDefault}
            color="indigo"
            className="cursor-not-allowed text-gray-900"
            disabled={true}
          />
        )}
      </TableCell>
      <TableCell className="whitespace-nowrap text-sm font-medium">
        <div className="inline-flex space-x-2">
          <>
            {showBreakdown ? (
              <>
                <Button
                  color="indigo"
                  onClick={() => {
                    onAdd();
                  }}
                  disabled={breakdownCount >= totalCount}
                >
                  Add to all
                </Button>
              </>
            ) : (
              <>
                {allowEditing && (
                  <button
                    type="button"
                    className="px-2 py-1 text-sm font-medium text-indigo-600 "
                    onClick={() => {
                      // Handle edit action
                    }}
                  >
                    <PencilSquareIcon
                      className="h-6 w-6 flex-shrink-0"
                      aria-hidden="true"
                    />
                  </button>
                )}
              </>
            )}
            <Button
              outline
              onClick={onRemove}
            >
              <XMarkIcon />
            </Button>
          </>
        </div>
      </TableCell>
    </TableRow>
  );
};

const TypeBadge = ({ type }: { type: string }) => {
  switch (type) {
    case 'CONTENT':
      return <FlowbiteBadge color="blue">{type}</FlowbiteBadge>;
    case 'SOUND':
      return <FlowbiteBadge color="green">{type}</FlowbiteBadge>;
    case 'MENTION':
      return <FlowbiteBadge color="purple">{type}</FlowbiteBadge>;
    case 'HASHTAG':
      return <FlowbiteBadge color="yellow">{type}</FlowbiteBadge>;
    default:
      return <FlowbiteBadge color="zinc">{type}</FlowbiteBadge>;
  }
};

const requirementForMention = (
  referenceBrand: Brand,
  externalPlatform: string
) => {
  return (
    (externalPlatform === 'INSTAGRAM'
      ? 'Instagram: @' + referenceBrand?.socialHandle
      : 'TikTok: @' + referenceBrand?.tiktokSocialHandle) +
    ` (${referenceBrand?.name})`
  );
};

const emojiForMention = (externalPlatform: string) => {
  return externalPlatform === 'INSTAGRAM' ? <InstagramLogo /> : <TiktokLogo />;
};
