import { Fragment, useContext, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import {
  UsersIcon,
  PhotoIcon,
  Bars3Icon,
  XMarkIcon,
  BuildingStorefrontIcon,
  StarIcon,
  PuzzlePieceIcon,
  WalletIcon,
  TicketIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  QueueListIcon,
  UserGroupIcon,
} from '@heroicons/react/24/outline';
import { Link, NavLink } from 'react-router-dom';
import { UserContext } from '../../providers/UserProvider';
import EnvelopeIcon from '@heroicons/react/24/outline/esm/EnvelopeIcon';

let navigation = [
  { name: 'Creator Lookup', href: '/creators', icon: UsersIcon },
  {
    name: 'Challenges',
    icon: StarIcon,
    subTabs: [
      { name: 'Overview', href: '/challenges' },
      { name: 'Templates', href: '/templates' },
      { name: 'Requirements', href: '/requirements' },
    ],
  },
  {
    name: 'Brands',
    icon: BuildingStorefrontIcon,
    subTabs: [
      { name: 'Kale Brands', href: '/brands' },
      { name: 'Non-Kale Brands', href: '/nonKaleBrands' },
      { name: 'Health Check', href: '/healthCheck' },
    ],
  },
  { name: 'Bonsai', href: '/bonsai', icon: QueueListIcon },
  { name: 'Kale Posts', href: '/posts', icon: PhotoIcon },
  { name: 'Scores', href: '/scores', icon: PuzzlePieceIcon },
  { name: 'Whitelist Requests', href: '/whitelists', icon: WalletIcon },
  { name: 'Notifications', href: '/notifications', icon: EnvelopeIcon },
  { name: 'Offers', href: '/offers', icon: TicketIcon },
];

export default function Sidebar() {
  const { user } = useContext(UserContext);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [challengesSubTabsOpen, setChallengesSubTabsOpen] = useState(true);
  const [brandsSubTabsOpen, setBrandsSubTabsOpen] = useState(false);

  if (
    JSON.parse(process.env.REACT_APP_USER_MANAGEMENT_ADMIN_IDS).includes(
      user.uid
    ) &&
    !navigation.find((tab) => tab.name === 'User Management')
  ) {
    navigation.push({
      name: 'User Management',
      href: '/userManagement',
      icon: UserGroupIcon,
    });
  }

  const desktopPhoto = (
    <div>
      <img
        className="inline-block h-9 w-9 rounded-full border border-kale-green-500"
        src={user?.photoURL}
        alt=""
      />
    </div>
  );

  const desktopAvatar = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-9 w-9"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
      />
    </svg>
  );

  const renderSidebarItem = (item) => {
    return (
      <div className="flex items-center">
        {item.icon && (
          <item.icon
            className="mr-3 h-6 w-6 flex-shrink-0 text-gray-500"
            aria-hidden="true"
          />
        )}
        {item.name}
      </div>
    );
  };

  const renderSubTabsIcon = (item) => {
    return isSubTabsOpen(item) ? (
      <ChevronDownIcon className="h-4 w-4 text-gray-700" />
    ) : (
      <ChevronRightIcon className="h-4 w-4 text-gray-700" />
    );
  };

  const isSubTabsOpen = (item) => {
    switch (item.name) {
      case 'Brands':
        return brandsSubTabsOpen;
      case 'Challenges':
        return challengesSubTabsOpen;
      default:
        return null;
    }
  };

  const toggleSubTabs = (item) => {
    switch (item.name) {
      case 'Brands':
        setBrandsSubTabsOpen(!brandsSubTabsOpen);
        break;
      case 'Challenges':
        setChallengesSubTabsOpen(!challengesSubTabsOpen);
        break;
      default:
        break;
    }
  };

  return (
    <div className="flex h-screen overflow-hidden bg-white">
      <Transition.Root
        show={sidebarOpen}
        as={Fragment}
      >
        <Dialog
          as="div"
          className="fixed inset-0 z-40 flex md:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex w-full max-w-xs flex-1 flex-col bg-white">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute right-0 top-0 -mr-12 pt-2">
                  <button
                    className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XMarkIcon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </button>
                </div>
              </Transition.Child>
              <div className="h-0 flex-1 overflow-y-auto pb-4 pt-5">
                <div className="flex flex-shrink-0 items-center px-4">
                  <p className="text-2xl font-semibold text-gray-900">
                    Kale Admin
                  </p>
                </div>
                <nav className="mt-5 space-y-1 px-2">
                  {navigation.map((item) => (
                    <div key={item.name}>
                      {item.subTabs?.length > 0 ? (
                        <div>
                          <button
                            className="group flex w-full items-center justify-between rounded-md px-2 py-2 text-sm text-gray-600 hover:bg-kale-mint-300 hover:text-gray-900"
                            aria-controls="sub-menu-1"
                            aria-expanded="false"
                            onClick={() => toggleSubTabs(item)}
                          >
                            {renderSidebarItem(item)}
                            {renderSubTabsIcon(item)}
                          </button>
                          {isSubTabsOpen(item) && (
                            <ul
                              className="mt-1 space-y-1"
                              id="sub-menu-1"
                            >
                              {item.subTabs.map((subTab) => {
                                return (
                                  <li
                                    className="pl-10"
                                    key={subTab.name}
                                  >
                                    <NavLink
                                      key={subTab.name}
                                      to={subTab.href}
                                      activeClassName="bg-kale-mint-400 text-gray-900 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                                      className="group flex items-center justify-between rounded-md px-2 py-2 text-sm text-gray-600 hover:bg-kale-mint-300 hover:text-gray-900"
                                    >
                                      {renderSidebarItem(subTab)}
                                    </NavLink>
                                  </li>
                                );
                              })}
                            </ul>
                          )}
                        </div>
                      ) : (
                        <NavLink
                          key={item.name}
                          to={item.href}
                          activeClassName="bg-kale-mint-400 text-gray-900 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                          className="group flex items-center justify-between rounded-md px-2 py-2 text-sm text-gray-600 hover:bg-kale-mint-300 hover:text-gray-900"
                        >
                          {renderSidebarItem(item)}
                        </NavLink>
                      )}
                    </div>
                  ))}
                </nav>
              </div>
              <div className="flex flex-shrink-0 border-t border-gray-200 p-4">
                <Link
                  to="/profile"
                  className="group block flex-shrink-0"
                >
                  <div className="flex items-center">
                    {user?.photoURL ? desktopPhoto : desktopAvatar}
                    <div className="ml-3">
                      <p className="text-base font-medium text-gray-700 group-hover:text-gray-900">
                        {user?.displayName && user.displayName}
                      </p>
                      <p className="text-sm font-medium text-gray-500 group-hover:text-gray-700">
                        View profile
                      </p>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </Transition.Child>
          <div className="w-14 flex-shrink-0">
            {/* Force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden md:flex md:flex-shrink-0">
        <div className="flex w-64 flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex h-0 flex-1 flex-col border-r border-gray-200 bg-white">
            <div className="flex flex-1 flex-col overflow-y-auto pb-4 pt-5">
              <div className="flex flex-shrink-0 items-center px-4">
                <p className="text-2xl font-semibold text-gray-900">
                  Kale Admin
                </p>
              </div>
              <nav className="mt-5 flex-1 space-y-1 bg-white px-2">
                {navigation.map((item) => (
                  <div key={item.name}>
                    {item.subTabs?.length > 0 ? (
                      <div>
                        <button
                          className="group flex w-full items-center justify-between rounded-md px-2 py-2 text-sm text-gray-600 hover:bg-kale-mint-300 hover:text-gray-900"
                          aria-controls="sub-menu-1"
                          aria-expanded="false"
                          onClick={() => toggleSubTabs(item)}
                        >
                          {renderSidebarItem(item)}
                          {renderSubTabsIcon(item)}
                        </button>
                        {isSubTabsOpen(item) && (
                          <ul
                            className="mt-1 space-y-1"
                            id="sub-menu-1"
                          >
                            {item.subTabs.map((subTab) => {
                              return (
                                <li
                                  className="pl-10"
                                  key={subTab.name}
                                >
                                  <NavLink
                                    key={subTab.name}
                                    to={subTab.href}
                                    activeClassName="bg-kale-mint-400 text-gray-900 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                                    className="group flex items-center justify-between rounded-md px-2 py-2 text-sm text-gray-600 hover:bg-kale-mint-300 hover:text-gray-900"
                                  >
                                    {renderSidebarItem(subTab)}
                                  </NavLink>
                                </li>
                              );
                            })}
                          </ul>
                        )}
                      </div>
                    ) : (
                      <NavLink
                        key={item.name}
                        to={item.href}
                        activeClassName="bg-kale-mint-400 text-gray-900 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                        className="group flex items-center justify-between rounded-md px-2 py-2 text-sm text-gray-600 hover:bg-kale-mint-300 hover:text-gray-900"
                      >
                        {renderSidebarItem(item)}
                      </NavLink>
                    )}
                  </div>
                ))}
              </nav>
            </div>
            <div className="flex flex-shrink-0 border-t border-gray-200 p-4">
              <Link
                to="/profile"
                className="group block w-full flex-shrink-0"
              >
                <div className="flex items-center">
                  {user?.photoURL ? desktopPhoto : desktopAvatar}
                  <div className="ml-3">
                    <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">
                      {user?.displayName && user.displayName}
                    </p>
                    <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700">
                      View profile
                    </p>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="pl-1 pt-1 sm:pl-3 sm:pt-3 md:hidden">
        <button
          className="-ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
          onClick={() => setSidebarOpen(true)}
        >
          <span className="sr-only">Open sidebar</span>
          <Bars3Icon
            className="h-6 w-6"
            aria-hidden="true"
          />
        </button>
      </div>
    </div>
  );
}
