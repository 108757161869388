import {
  BuildingStorefrontIcon,
  FaceSmileIcon,
  InformationCircleIcon,
  MinusCircleIcon,
  PlusCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { Button } from '../catalyst/button';
import { Field, Label } from '../catalyst/fieldset';
import { Textarea } from '../catalyst/textarea';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { Input } from '../catalyst/input';
import { Switch, SwitchField } from '../catalyst/switch';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Badge } from '../catalyst/badge';
import { Strong, Text } from '../catalyst/text';
import { Dropdown, Tooltip } from 'flowbite-react';
import { useEffect, useRef, useState } from 'react';
import {
  CREATE_BRAND_PRODUCT,
  CREATE_POST_REQUIREMENT,
} from '../../graphql/mutations';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  OptionInterface,
  Spinner,
  Dropdown as KaleDropdown,
} from '@kalecard/common';
import {
  BRANDS,
  BRAND_PRODUCTS,
  CHALLENGE_CATEGORIES,
  ChallengeCategoriesInterface,
} from '../../graphql/queries';
import {
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { useEmojiPickerForInput } from '../../utils/emojiPicker';
import RequirementsTable from '../shared/RequirementsTable';
import {
  Brand,
  Post,
  PostRequirement,
  ChallengeCategory,
  BrandProduct,
  Challenge,
} from '../../__generated__/graphql';

function isValidLink(link) {
  const regex = /^https:\/\/.*/;
  return !link || regex.test(link);
}

export function ChallengeDifficulty({ difficulty }: { difficulty?: number }) {
  return (
    <div>
      {Array.from({ length: difficulty ?? 0 }, (_, i) => (
        <span
          className="text-base"
          key={i}
        >
          {' '}
          🥬{' '}
        </span>
      ))}
    </div>
  );
}

export const getRoundedDate = (date?: Date) => {
  return date ? Math.floor(date.getTime() / 1000) * 1000 : null;
};

function ChallengeDescriptionFormComponent({ register, setValue, getValues }) {
  const [isEmojiOpen, setIsEmojiOpen] = useState<boolean>(false);
  const pickerRef = useRef(null);
  const inputRef = useRef(null);
  const inputName = 'description';
  const querySelector = 'textarea';

  const { addEmojiAtCursor, handleCursorChange } = useEmojiPickerForInput({
    setValue,
    getValues,
    inputName,
    querySelector,
    pickerRef,
    inputRef,
    setIsEmojiOpen,
  });

  return (
    <Field>
      <Label>Description:</Label>
      <div
        className="flex space-x-2"
        ref={inputRef}
      >
        <Textarea
          {...register(inputName)}
          onClick={handleCursorChange}
          onChange={handleCursorChange}
        />
        <Button
          type="button"
          plain
          className="hover:cursor-pointer"
          onClick={() => setIsEmojiOpen(!isEmojiOpen)}
        >
          <FaceSmileIcon />
        </Button>
      </div>
      {isEmojiOpen && (
        <div className="relative flex">
          <div
            className="absolute left-0 top-0 z-10"
            ref={pickerRef}
          >
            <Picker
              data={data}
              onEmojiSelect={(emoji) => {
                addEmojiAtCursor(emoji);
              }}
              autoFocus={true}
            />
          </div>
        </div>
      )}
    </Field>
  );
}

function ChallengeTitleFormComponent({
  register,
  setValue,
  getValues,
  isEditForm,
}) {
  const [isEmojiOpen, setIsEmojiOpen] = useState<boolean>(false);
  const pickerRef = useRef(null);
  const inputRef = useRef(null);
  const inputName = 'title';
  const querySelector = 'input[type="text"]';

  const { addEmojiAtCursor, handleCursorChange } = useEmojiPickerForInput({
    setValue,
    getValues,
    inputName,
    querySelector,
    pickerRef,
    inputRef,
    setIsEmojiOpen,
  });

  return (
    <Field>
      <Label>Title:</Label>
      <div></div>
      <div
        className="flex space-x-2"
        ref={inputRef}
      >
        <Input
          maxLength={26}
          type="text"
          {...register(inputName, { required: !isEditForm })}
          onClick={handleCursorChange}
          onChange={handleCursorChange}
        />
        <Button
          type="button"
          plain
          className="hover:cursor-pointer"
          onClick={() => setIsEmojiOpen(!isEmojiOpen)}
        >
          <FaceSmileIcon />
        </Button>
      </div>
      {isEmojiOpen && (
        <div className="relative flex">
          <div
            className="absolute left-0 top-0 z-10"
            ref={pickerRef}
          >
            <Picker
              data={data}
              onEmojiSelect={(emoji) => {
                addEmojiAtCursor(emoji);
              }}
              autoFocus={true}
            />
          </div>
        </div>
      )}
    </Field>
  );
}

function ChallengeDifficultyFormComponent({ difficulty, setDifficulty }) {
  return (
    <Field>
      <Label>Difficulty:</Label>
      <div className="flex items-center space-x-2">
        {difficulty > 1 && (
          <Button
            type="button"
            plain
            className="hover:cursor-pointer"
            onClick={() => setDifficulty(difficulty - 1)}
          >
            <MinusCircleIcon />
          </Button>
        )}
        <ChallengeDifficulty difficulty={difficulty ?? 1} />
        {difficulty < 3 && (
          <Button
            type="button"
            plain
            className="hover:cursor-pointer"
            onClick={() => setDifficulty(difficulty + 1)}
          >
            <PlusCircleIcon />
          </Button>
        )}
      </div>
    </Field>
  );
}

function ChallengeExampleUrlFormComponent({ register }) {
  return (
    <Field>
      <Label>Example URL:</Label>
      <div>
        <Input
          type="text"
          {...register('exampleUrl', { validate: isValidLink })}
        />
      </div>
    </Field>
  );
}

function ChallengeCommercialSoundsOnlyFormComponent({
  isCommercialSoundsOnly,
  setIsCommercialSoundsOnly,
}) {
  return (
    <SwitchField className="w-fit">
      <Label className="font-medium">Commercial Sounds Only</Label>
      <Switch
        color="emerald"
        key={isCommercialSoundsOnly}
        defaultChecked={isCommercialSoundsOnly}
        onChange={setIsCommercialSoundsOnly}
      />
    </SwitchField>
  );
}

export function ChallengeDatesFormComponent({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}) {
  const date = new Date();

  return (
    <div className="flex space-x-4">
      {/* Start Date */}
      <Field>
        <Label>Activation Date:</Label>
        <div>
          <DatePicker
            className="mt-1 rounded-md border-gray-300 text-sm shadow-sm"
            selected={startDate}
            showTimeSelect
            onChange={(date) => setStartDate(date)}
            dateFormat="MM/dd/yyyy HH:mm"
            minDate={new Date().setDate(date.getDate() + 1)}
          />
        </div>
      </Field>

      {/* End Date */}
      <Field>
        <Label>Deactivation Date:</Label>
        <div>
          <DatePicker
            className="mt-1 rounded-md border-gray-300 text-sm shadow-sm"
            selected={endDate}
            showTimeSelect
            onChange={(date) => setEndDate(date)}
            dateFormat="MM/dd/yyyy HH:mm"
            minDate={
              startDate
                ? new Date(startDate)
                : new Date().setDate(date.getDate() + 1)
            }
          />
        </div>
      </Field>
    </div>
  );
}

function ChallengeBrandProductComponent({
  brand,
  selectedBrandProduct,
  setSelectedBrandProduct,
}) {
  const [showBrandProductInput, setShowBrandProductInput] = useState(false);
  const [brandProducts, setBrandProducts] = useState([]);
  const [isNewBrandProductLoading, setIsNewBrandProductLoading] =
    useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [newProductExternalUrl, setNewProductExternalUrl] = useState(null);
  const [createBrandProduct] = useMutation(CREATE_BRAND_PRODUCT, {
    refetchQueries: [BRANDS],
  });

  const handleNewBrandProduct = async () => {
    if (newProductExternalUrl && !isValidLink(newProductExternalUrl)) {
      setErrorMessage(
        "Invalid URL format. Please enter a product URL that starts with 'https://'"
      );
      return;
    }

    setIsNewBrandProductLoading(true);
    try {
      const result = await createBrandProduct({
        variables: {
          brandId: brand.id,
          externalUrl: newProductExternalUrl,
        },
      });
      if (result.data.createBrandProduct) {
        setBrandProducts([...brandProducts, result.data.createBrandProduct]);
        setSelectedBrandProduct(result.data.createBrandProduct);
      }
      console.log(result);
      setErrorMessage(null);
    } catch (err) {
      console.error(err);
      setErrorMessage('Failed to apply your changes, try again later.');
    }
    setIsNewBrandProductLoading(false);
    setShowBrandProductInput(false);
  };

  interface BrandProductInterface {
    brandProducts: BrandProduct[];
  }
  const { data: brandProductData, loading } = useQuery<BrandProductInterface>(
    BRAND_PRODUCTS,
    {
      variables: { brandId: brand?.id },
      fetchPolicy: 'network-only',
      skip: !brand,
    }
  );

  const handleSelectBrandProduct = (brandProduct) => {
    setSelectedBrandProduct(brandProduct);
    setShowBrandProductInput(false);
  };

  useEffect(() => {
    if (
      brandProducts.length === 0 &&
      brandProductData?.brandProducts?.length > 0
    ) {
      setBrandProducts([...brandProducts, ...brandProductData.brandProducts]);
    } else if (brandProducts.length > 0) {
      setBrandProducts(
        brandProductData?.brandProducts
          ? [...brandProductData.brandProducts]
          : []
      );
      setSelectedBrandProduct(null);
    }
  }, [brandProductData]);

  const truncateExternalUrl = (externalUrl) => {
    const maxLength = 44;
    const truncatedText =
      externalUrl.length > maxLength
        ? externalUrl.substring(0, maxLength) + '...'
        : externalUrl;

    return truncatedText;
  };

  return (
    brand && (
      <Field>
        <div className="flex items-center space-x-2">
          <Label>
            <Strong>Required Brand Product:</Strong>
          </Label>
          <Tooltip
            content={
              'This should not be a highly used functionality. The majority of challenges should NOT have a product link.'
            }
          >
            <InformationCircleIcon className="h-4 w-4" />
          </Tooltip>
        </div>
        <div className="flex justify-between">
          <Dropdown
            label={
              selectedBrandProduct?.externalUrl
                ? truncateExternalUrl(selectedBrandProduct.externalUrl)
                : 'Select an existing brand product'
            }
            color="gray"
          >
            {brandProducts.map((brandProduct) => (
              <Dropdown.Item
                key={brandProduct.id}
                className={`${
                  brandProduct.id === selectedBrandProduct?.id
                    ? 'bg-gray-200'
                    : ''
                } hover:bg-gray-100`}
                onClick={() => handleSelectBrandProduct(brandProduct)}
              >
                {brandProduct.externalUrl}
              </Dropdown.Item>
            ))}
            {brandProducts.length === 0 && (
              <Dropdown.Item>No brand products yet! Create one.</Dropdown.Item>
            )}
          </Dropdown>
          <Button
            color="emerald"
            className="hover:cursor-pointer"
            onClick={() => setShowBrandProductInput(true)}
            disabled={showBrandProductInput}
          >
            New Brand Product
          </Button>
        </div>

        {showBrandProductInput && (
          <Field className="mt-2 rounded-md bg-gray-100 p-4">
            <Label>Enter the product's external URL:</Label>
            <div className="flex items-center space-x-2">
              <Input
                onChange={(e) => setNewProductExternalUrl(e.target.value)}
              />
              <Button
                color="emerald"
                className="hover:cursor-pointer"
                onClick={() => handleNewBrandProduct()}
              >
                Submit
              </Button>
              {isNewBrandProductLoading && <Spinner size="h-6 w-6" />}
            </div>
          </Field>
        )}

        {errorMessage && (
          <div className="pt-2 text-sm font-medium text-red-500">
            {errorMessage}
          </div>
        )}
      </Field>
    )
  );
}

function ChallengeTagsFormComponent({
  addHashtagRequirements,
  hashtagRequirements,
  removeHashtagRequirement,
}) {
  const [createPostRequirement] = useMutation(CREATE_POST_REQUIREMENT);
  const [tagString, setTagString] = useState('');
  const handleNewTag = async () => {
    // remove any whitespace from the new tag
    const words = tagString?.match(/\b\w+\b/g);

    // Add a # in front of each word iff they don't have it.
    const newTagWords = words?.map((word) => {
      if (word[0] !== '#') {
        return '#' + word;
      }
      return word;
    });

    const newReqs = [];
    await Promise.all(
      newTagWords?.map(async (word) => {
        const result = await createPostRequirement({
          variables: {
            type: 'HASHTAG',
            requirement: word,
          },
        });
        newReqs.push(result?.data?.createPostRequirement);
      })
    );
    addHashtagRequirements(newReqs);
    setTagString('');
  };

  return (
    <Field>
      <Label>Hashtag Requirements:</Label>
      <div className="flex flex-row space-x-2">
        <Input
          type="text"
          onKeyDown={(event) => {
            const { key } = event;
            if (key === 'Enter' || key === ' ') {
              handleNewTag();
            }
          }}
          onChange={(e) => {
            setTagString(e.target.value);
          }}
          value={tagString}
        />

        <Button
          type="button"
          color="emerald"
          className="hover:cursor-pointer"
          onClick={() => {
            handleNewTag();
          }}
        >
          Add
        </Button>
      </div>
      {hashtagRequirements && (
        <div className="flex flex-wrap gap-y-2 pt-2">
          {Array.from(hashtagRequirements).map((tag: PostRequirement) => {
            return (
              <Badge
                color="emerald"
                className="mr-2"
                key={tag.id}
              >
                <Text>{tag.requirement}</Text>
                <Button
                  type="button"
                  className="hover:cursor-pointer"
                  plain
                  onClick={() => {
                    removeHashtagRequirement(tag);
                  }}
                >
                  <XMarkIcon />
                </Button>
              </Badge>
            );
          })}
        </div>
      )}
    </Field>
  );
}

function ChallengeDetailsComponent({
  brandOptions,
  brands,
  selectedBrandId,
  brandClicked,
  isChallengeReviewRequired,
  challengeCategory,
  setChallengeCategory,
  register,
  setValue,
  getValues,
  difficulty,
  setDifficulty,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  addTags,
  tagRequirements,
  removeTagRequirement,
  isCommercialSoundsOnly,
  setIsCommercialSoundsOnly,
  isEditForm,
  shouldShowCommercialSoundsInput,
}) {
  const [
    getChallengeCategories,
    { data: categoriesData, loading: categoriesLoading },
  ] = useLazyQuery<ChallengeCategoriesInterface>(CHALLENGE_CATEGORIES);

  const [shouldLimitCategories, setShouldLimitCategories] = useState(false);

  useEffect(() => {
    if (shouldLimitCategories) {
      getChallengeCategories({
        variables: { brandId: selectedBrandId },
      });
    } else {
      getChallengeCategories();
    }
  }, [shouldLimitCategories, selectedBrandId]);

  const handleCategoryClicked = (category: OptionInterface) => {
    setChallengeCategory(
      categoriesData?.challengeCategories.find((cat) => cat.id === category.id)
    );
    setValue('title', category.name);
  };

  const buildTemplate = () => {
    if (challengeCategory) {
      const selectedBrand = brands.find(
        (brand) => brand.id === selectedBrandId
      );
      return challengeCategory.template.replaceAll(
        '{{brand_name}}',
        selectedBrand?.name ?? '<BRAND>'
      );
    } else {
      return getValues('description');
    }
  };

  useEffect(() => {
    if (!getValues('description')) {
      setValue('description', buildTemplate());
    }
  }, [challengeCategory, selectedBrandId]);

  return (
    <div className="space-y-4">
      {/* Brand */}
      {!isEditForm && (
        <Field>
          <Label>Brand:</Label>
          <KaleDropdown
            options={brandOptions}
            activeOptionId={selectedBrandId}
            optionClicked={brandClicked}
            label={null}
            placeholderImage={
              <BuildingStorefrontIcon className="h-6 w-6 flex-shrink-0 rounded-full" />
            }
          />
          {isChallengeReviewRequired && (
            <div>
              <p className="pt-2 text-sm font-medium">
                🔔 Challenge Review Required
              </p>
            </div>
          )}
        </Field>
      )}

      {/* Challenge Category */}
      <Field>
        <Label>Template:</Label>
        <div>
          {!categoriesLoading ? (
            <KaleDropdown
              options={categoriesData?.challengeCategories.map((category) => {
                return { ...category, imageUrl: '' };
              })}
              activeOptionId={challengeCategory?.id}
              optionClicked={(option) => handleCategoryClicked(option)}
              label={''}
            />
          ) : (
            <div className="flex justify-center pt-2">
              <Spinner size={'h-4 w-4'} />
            </div>
          )}
        </div>
        {selectedBrandId ? (
          <div>
            <input
              id="limitCategories"
              name="limitCategories"
              checked={shouldLimitCategories}
              type="checkbox"
              disabled={categoriesLoading}
              className="h-4 w-4 rounded border-gray-300 text-gray-600 focus:ring-gray-500"
              onChange={(e) => setShouldLimitCategories(e.target.checked)}
            />
            <label
              htmlFor="limitCategories"
              className="ml-2 text-sm"
            >
              Limit to templates for selected brand
            </label>
          </div>
        ) : null}
      </Field>

      {/* Challenge Title */}
      <ChallengeTitleFormComponent
        register={register}
        setValue={setValue}
        getValues={getValues}
        isEditForm={isEditForm}
      />

      {/* Description */}
      <ChallengeDescriptionFormComponent
        register={register}
        setValue={setValue}
        getValues={getValues}
      />

      {/* Difficulty level */}
      <ChallengeDifficultyFormComponent
        difficulty={difficulty}
        setDifficulty={setDifficulty}
      />

      {/* Example Url */}
      <ChallengeExampleUrlFormComponent register={register} />

      {/* Tag requirements */}
      <ChallengeTagsFormComponent
        addHashtagRequirements={addTags}
        hashtagRequirements={tagRequirements}
        removeHashtagRequirement={removeTagRequirement}
      />

      {/* Commercial Sounds Only */}
      {shouldShowCommercialSoundsInput && (
        <ChallengeCommercialSoundsOnlyFormComponent
          isCommercialSoundsOnly={isCommercialSoundsOnly}
          setIsCommercialSoundsOnly={setIsCommercialSoundsOnly}
        />
      )}

      {/* Dates */}
      <ChallengeDatesFormComponent
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
      />
    </div>
  );
}

function ChallengeAdditionalInfoComponent({
  selectedBrandId,
  brands,
  selectedBrandProduct,
  setSelectedBrandProduct,
  challengePostRequirements,
  setChallengePostRequirements,
}) {
  const selectedBrand = selectedBrandId
    ? brands.find((brand) => brand.id === selectedBrandId)
    : null;
  return (
    selectedBrand && (
      <div className="space-y-4">
        {/* Brand Product */}
        <ChallengeBrandProductComponent
          brand={selectedBrand}
          selectedBrandProduct={selectedBrandProduct}
          setSelectedBrandProduct={setSelectedBrandProduct}
        />
      </div>
    )
  );
}

export function ChallengeFormDataComponent({
  brandOptions,
  brands,
  selectedBrandId,
  brandClicked,
  isChallengeReviewRequired,
  challengeCategory,
  setChallengeCategory,
  register,
  setValue,
  getValues,
  watch,
  difficulty,
  setDifficulty,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  isCommercialSoundsOnly,
  setIsCommercialSoundsOnly,
  selectedBrandProduct,
  setSelectedBrandProduct,
  challengePostRequirements,
  setChallengePostRequirements,
  setError,
  isEditForm,
  shouldShowCommercialSoundsInput,
  currentChallenge,
}: {
  brandOptions?: OptionInterface[];
  brands: Brand[];
  selectedBrandId?: string;
  brandClicked?: (brandOption: OptionInterface) => void;
  isChallengeReviewRequired?: boolean;
  challengeCategory?: ChallengeCategory;
  setChallengeCategory?: (challengeCategory: ChallengeCategory) => void;
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
  getValues: UseFormGetValues<any>;
  watch: UseFormWatch<any>;
  difficulty: number;
  setDifficulty: (difficulty: number) => void;
  startDate?: Date;
  setStartDate: (startDate: Date) => void;
  endDate?: Date;
  setEndDate: (endDate: Date) => void;
  isCommercialSoundsOnly: boolean;
  setIsCommercialSoundsOnly: (isCommercialSoundsOnly: boolean) => void;
  isEditForm: boolean;
  shouldShowCommercialSoundsInput: boolean;
  selectedBrandProduct?: BrandProduct;
  setSelectedBrandProduct: (brandProduct: BrandProduct) => void;
  challengePostRequirements: PostRequirement[];
  setChallengePostRequirements: (
    challengePostRequirements: PostRequirement[]
  ) => void;
  setError: (error: string) => void;
  currentChallenge?: Challenge;
}) {
  const [tabs, setTabs] = useState([
    { name: 'Details', selected: true },
    { name: 'Additional Info', selected: false },
  ]);

  const getTabComponent = (tab) => {
    switch (tab.name) {
      case 'Details':
        return (
          <div
            hidden={!tab.selected}
            key={tab.name}
            className="rounded-md bg-gray-200 p-4"
          >
            <ChallengeDetailsComponent
              brandOptions={brandOptions}
              brands={brands}
              selectedBrandId={selectedBrandId}
              brandClicked={brandClicked}
              isChallengeReviewRequired={isChallengeReviewRequired}
              challengeCategory={challengeCategory}
              setChallengeCategory={setChallengeCategory}
              register={register}
              setValue={setValue}
              getValues={getValues}
              difficulty={difficulty}
              setDifficulty={setDifficulty}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              tagRequirements={challengePostRequirements?.filter(
                (req) => req.type == 'HASHTAG'
              )}
              addTags={(tags) => {
                setChallengePostRequirements([
                  ...challengePostRequirements,
                  ...tags,
                ]);
              }}
              removeTagRequirement={(tag) => {
                setChallengePostRequirements(
                  challengePostRequirements.filter((req) => req.id !== tag.id)
                );
              }}
              isCommercialSoundsOnly={isCommercialSoundsOnly}
              setIsCommercialSoundsOnly={setIsCommercialSoundsOnly}
              isEditForm={isEditForm}
              shouldShowCommercialSoundsInput={shouldShowCommercialSoundsInput}
            />
          </div>
        );

      case 'Additional Info':
        return (
          <div
            hidden={!tab.selected}
            key={tab.name}
            className="rounded-md bg-gray-200 p-4"
          >
            <ChallengeAdditionalInfoComponent
              selectedBrandId={selectedBrandId}
              brands={brands}
              selectedBrandProduct={selectedBrandProduct}
              setSelectedBrandProduct={setSelectedBrandProduct}
              challengePostRequirements={challengePostRequirements}
              setChallengePostRequirements={setChallengePostRequirements}
            />
          </div>
        );
    }
  };

  const handleTabClick = (selectedTab) => {
    if (selectedTab.name === 'Additional Info' && !selectedBrandId) {
      setError(
        'Please select a brand before moving onto the Additional Info tab.'
      );
      return;
    }
    const updatedTabs = tabs.map((tab) => {
      if (tab.name === selectedTab.name) {
        return { ...tab, selected: true };
      } else {
        return { ...tab, selected: false };
      }
    });

    setTabs(updatedTabs);
  };

  return (
    <div className="flex w-full">
      <div className="w-full space-y-2">
        <div className="flex space-x-8 border-b text-sm">
          {tabs.map((tab) => (
            <div
              key={tab.name}
              className={`${
                tab.selected ? 'border-b border-gray-500 pb-2 font-medium' : ''
              } hover:cursor-pointer`}
              onClick={() => handleTabClick(tab)}
            >
              {tab.name}
            </div>
          ))}
        </div>
        {tabs.map((tab) => getTabComponent(tab))}
        <div className="m-4">
          <RequirementsTable
            allowCreating
            currentActiveRequirements={challengePostRequirements}
            challenge={currentChallenge}
            onAddRequirement={(requirement) =>
              setChallengePostRequirements([
                ...challengePostRequirements,
                requirement,
              ])
            }
            onRemoveRequirement={(requirement) =>
              setChallengePostRequirements(
                challengePostRequirements.filter(
                  (req) => req.id !== requirement.id
                )
              )
            }
          />
        </div>
      </div>
    </div>
  );
}
