import { Spinner } from '@kalecard/common';
import {
  CHALLENGE_POST_REQUIREMENTS_BREAKDOWN,
  POST_REQUIREMENTS,
  PostRequirementsInterface,
} from '../graphql/queries';
import { useMutation, useQuery } from '@apollo/client';
import { useEffect, useRef, useState } from 'react';
import {
  UPDATE_POST_REQUIREMENT,
  UPDATE_POST_REQUIREMENTS_FOR_BRAND,
  UPDATE_POST_REQUIREMENTS_FOR_BRAND_CHALLENGES,
} from '../graphql/mutations';
import RequirementsTable from '../components/shared/RequirementsTable';
import {
  Brand,
  BrandPostRequirementBreakdown,
  PostRequirement,
} from '../__generated__/graphql';
import { Switch } from '../components/catalyst/switch';
import { Strong } from '../components/catalyst/text';

export default function BrandRequirementsPage({ brand }: { brand: Brand }) {
  const { data, loading, refetch } = useQuery<PostRequirementsInterface>(
    POST_REQUIREMENTS,
    {
      variables: { brandId: brand.id },
      fetchPolicy: 'network-only',
    }
  );
  const { data: globalData, loading: globalLoading } = useQuery(
    CHALLENGE_POST_REQUIREMENTS_BREAKDOWN,
    {
      variables: { brandId: brand.id },
    }
  );

  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [updatePostRequirementsForBrand] = useMutation(
    UPDATE_POST_REQUIREMENTS_FOR_BRAND,
    { refetchQueries: [POST_REQUIREMENTS] }
  );

  // TODO not really used right now
  const [updatePostRequirement] = useMutation(UPDATE_POST_REQUIREMENT, {
    refetchQueries: [POST_REQUIREMENTS],
  });

  const [updatePostRequirementsForBrandChallenges] = useMutation(
    UPDATE_POST_REQUIREMENTS_FOR_BRAND_CHALLENGES,
    {
      refetchQueries: [CHALLENGE_POST_REQUIREMENTS_BREAKDOWN],
    }
  );

  const [updatedPostRequirements, setUpdatedPostRequirements] = useState(
    data?.postRequirements ?? []
  );

  const [isGlobalUpdateActive, setIsGlobalUpdateActive] = useState(false);

  useEffect(() => {
    if (data?.postRequirements) {
      setUpdatedPostRequirements(data.postRequirements);
    }
  }, [data]);

  return !loading ? (
    <div className="rounded-md border-2 p-2">
      <div className="flex flex-row items-center space-x-2">
        <Switch
          checked={isGlobalUpdateActive}
          onChange={() => setIsGlobalUpdateActive(!isGlobalUpdateActive)}
          color="green"
        />
        <Strong>
          {isGlobalUpdateActive ? 'Existing Challenges' : 'Future Challenges'}
        </Strong>
      </div>
      <RequirementsTable
        showPreview={false}
        allowCreating
        showSearch
        brandId={brand.id}
        currentActiveRequirements={data?.postRequirements as PostRequirement[]}
        currentBreakdownRequirements={
          globalData?.challengePostRequirementsBreakdown as BrandPostRequirementBreakdown
        }
        onAddRequirement={(postRequirement: PostRequirement) => {
          if (isGlobalUpdateActive) {
            updatePostRequirementsForBrandChallenges({
              variables: {
                brandId: brand.id,
                postRequirementIdsToAdd: [postRequirement.id],
                postRequirementIdsToRemove: [],
              },
            });
          } else {
            updatePostRequirementsForBrand({
              variables: {
                brandId: brand.id,
                postRequirementIds: data?.postRequirements
                  ?.map((postRequirement) => postRequirement.id)
                  ?.concat([postRequirement.id]),
              },
            });
          }
        }}
        onRemoveRequirement={(postRequirement: PostRequirement) => {
          if (isGlobalUpdateActive) {
            updatePostRequirementsForBrandChallenges({
              variables: {
                brandId: brand.id,
                postRequirementIdsToAdd: [],
                postRequirementIdsToRemove: [postRequirement.id],
              },
            });
          } else {
            updatePostRequirementsForBrand({
              variables: {
                brandId: brand.id,
                postRequirementIds: data?.postRequirements
                  ?.map((postRequirement) => postRequirement.id)
                  ?.filter((id) => id !== postRequirement.id),
              },
            });
          }
        }}
        showBreakdown={isGlobalUpdateActive}
        showLoading={globalLoading}
      />
    </div>
  ) : (
    <div className="flex justify-center pt-10">
      <Spinner size={'h-8 w-8'} />
    </div>
  );
}
