import React, { useEffect, useState } from 'react';
import {
  AtSymbolIcon,
  ArrowTopRightOnSquareIcon,
  HashtagIcon,
  SpeakerWaveIcon,
  VideoCameraIcon,
} from '@heroicons/react/24/solid';
import {
  Brand,
  BrandProduct,
  Challenge,
  PostRequirement,
} from '../../__generated__/graphql';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { InstagramLogo, TiktokLogo } from '@kalecard/common';

interface Tab {
  name: string;
  selected: boolean;
  icon?: any;
}

export function ChallengePreview({
  currentRequirements,
  brandLogoUrl,
  brandName,
  exampleUrl,
  requiredBrandProducts,
  title,
  difficulty,
  description,
}: {
  currentRequirements: PostRequirement[];
  brandLogoUrl?: string;
  brandName?: string;
  exampleUrl?: string;
  requiredBrandProducts?: BrandProduct[];
  title?: string;
  difficulty?: number;
  description?: string;
}) {
  // const postRequirements = challenge?.postRequirements;

  const [tabs, setTabs] = useState<Tab[]>([
    { name: 'Details', selected: true },
    { name: 'Inspiration', selected: false },
  ]);

  useEffect(() => {
    const exampleUrlTab = {
      name: 'See Example',
      selected: false,
      icon: ArrowTopRightOnSquareIcon,
    };
    const existingTab = tabs.find((tab) => tab.name === exampleUrlTab.name);
    if (exampleUrl) {
      if (!existingTab) {
        setTabs([...tabs, exampleUrlTab]);
      }
    } else {
      if (existingTab) {
        const newTabs = tabs.filter((tab) => tab.name != exampleUrlTab.name);
        setTabs(newTabs);
      }
    }

    const productTab = {
      name: 'Product',
      selected: false,
      icon: ArrowTopRightOnSquareIcon,
    };
    const existingProductTab = tabs.find((tab) => tab.name === productTab.name);
    if ((requiredBrandProducts?.length ?? 0) > 0) {
      if (!existingProductTab) {
        setTabs([...tabs, productTab]);
      }
    } else {
      if (existingProductTab) {
        const newTabs = tabs.filter((tab) => tab.name != productTab.name);
        setTabs(newTabs);
      }
    }
  }, [exampleUrl, requiredBrandProducts]);

  return (
    <div className="flex h-fit w-full flex-col space-y-4 rounded-md border p-4">
      {/* Brand Logo and Name */}
      {brandLogoUrl && brandName && (
        <div className="flex flex-col items-center">
          <img
            src={brandLogoUrl}
            className="h-14 w-14"
          />
          <p className="pt-2 text-center text-2xl font-bold text-black">
            {brandName}
          </p>
        </div>
      )}

      {/* Tabs */}
      <div className="flex space-x-4 border-b text-xs">
        {tabs.map((tab) => (
          <div
            key={tab.name}
            className={`${
              tab.selected ? 'border-b border-gray-500 pb-2 font-medium' : ''
            } flex items-start space-x-1`}
          >
            <p>{tab.name}</p>
            {tab.icon && <tab.icon className="h-4 w-4" />}
          </div>
        ))}
      </div>

      <div className="space-y-4 divide-y">
        {/* Challenge Card */}
        <ChallengeDescriptionCard
          title={title}
          difficulty={difficulty}
          description={description}
        />

        {/* Requirements */}
        <div className="space-y-8 pt-4">
          {/* TODO Simplify this to one component */}
          <PostRequirements
            hashtagRequirements={
              currentRequirements?.filter(
                (requirement) => requirement.type == 'HASHTAG'
              ) ?? []
            }
            mentionRequirements={
              currentRequirements?.filter(
                (requirement) => requirement.type == 'MENTION'
              ) ?? []
            }
            soundRequirements={
              currentRequirements?.filter(
                (requirement) => requirement.type == 'SOUND'
              ) ?? []
            }
          />
          <ContentRequirements
            contentRequirements={
              currentRequirements?.filter(
                (requirement) => requirement.type == 'CONTENT'
              ) ?? []
            }
            brandName={brandName}
          />
        </div>
      </div>
    </div>
  );
}

function ChallengeDescriptionCard({
  title,
  difficulty,
  description,
}: {
  title: string;
  difficulty: number;
  description: string;
}) {
  const header = (
    <div className="flex items-center justify-between">
      <p className="text-lg font-medium text-black">{title}</p>
      {difficulty != null && difficulty > 0 ? (
        <div className="flex space-x-1">
          {Array.from({ length: difficulty ?? 0 }, (_, i) => (
            <p
              className="text-base"
              key={i}
            >
              🥬
            </p>
          ))}
        </div>
      ) : null}
    </div>
  );

  return (
    <div
      style={{
        background: '#DDFFE6',
      }}
      className="flex flex-col space-y-4 rounded-lg px-4 py-5"
    >
      {header}
      <p className="text-md text-base text-black">{description}</p>
    </div>
  );
}

type Props = {
  mentionRequirements: PostRequirement[];
  hashtagRequirements: PostRequirement[];
  soundRequirements?: PostRequirement[];
  hideSoundExampleLink?: boolean;
};

export function PostRequirements({
  mentionRequirements,
  hashtagRequirements,
  soundRequirements,
  hideSoundExampleLink = false,
}: Props) {
  return (
    <div className="flex flex-col space-y-7">
      <div className="justify-content flex items-start">
        <div className="pr-1 pt-1.5">
          <HashtagIcon className="h-4 w-4" />
        </div>
        <div className="ml-2 flex-1 flex-col space-y-2">
          <p className="text-base text-sm font-semibold text-black">
            Required hashtags
          </p>
          <p className="text-xs text-gray-600">
            {hashtagRequirements
              .map((postReq) => postReq.requirement)
              .join(' ')}
          </p>
        </div>
      </div>
      <div className="justify-content flex items-start">
        <div className="pr-1 pt-1.5">
          <AtSymbolIcon className="h-4 w-4" />
        </div>
        <div className="ml-2 flex-1 flex-col space-y-2">
          <p className="text-base text-sm font-semibold text-black">
            Mention requirements
          </p>
          <p className="text-xs text-gray-600">
            {mentionRequirements.map((postReq) =>
              RequirementForMention(postReq)
            )}
          </p>
        </div>
      </div>
      {soundRequirements.length > 0 ? (
        <div className="justify-content flex items-start">
          <div className="pr-1 pt-1.5">
            <SpeakerWaveIcon className="h-4 w-4" />
          </div>
          <div className="ml-2 flex flex-col space-y-2">
            <p className="text-base text-sm font-semibold text-black">
              Sound requirements
            </p>
            {soundRequirements.map((requirement) => (
              <p className="text-xs text-gray-600">{requirement.requirement}</p>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
}

export function ContentRequirements({
  contentRequirements,
  brandName,
}: {
  contentRequirements: PostRequirement[];
  brandName: string;
}) {
  const formatRequirement = (requirement: string) => {
    return requirement.replaceAll('{{brand_name}}', brandName ?? '<BRAND>');
  };
  return (
    <div className="flex flex-col space-y-3 pb-6">
      <div>
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-3">
            <VideoCameraIcon className="h-4 w-4" />
            <p className="text-base text-sm font-semibold text-black">
              Video Requirements
            </p>
          </div>
        </div>
      </div>
      <div>
        <div className="space-y-3 pl-6">
          {contentRequirements.map((requirement, index) => {
            return (
              <div
                key={index}
                className="flex items-center space-x-2"
              >
                <p className="text-right text-xs text-black">
                  {requirement.emoji}
                </p>
                <p className="text-xs text-gray-800">
                  {formatRequirement(requirement.requirement)}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

const RequirementForMention = (postRequirement: PostRequirement) => {
  const handle =
    postRequirement.externalPlatform === 'INSTAGRAM'
      ? postRequirement?.referenceBrand?.socialHandle
      : postRequirement?.referenceBrand?.tiktokSocialHandle;
  const Icon =
    postRequirement?.externalPlatform === 'INSTAGRAM' ? (
      <InstagramLogo />
    ) : (
      <TiktokLogo />
    );

  return (
    <div className="flex items-center space-x-2">
      {Icon}
      <p className="text-xs text-gray-600">@{handle}</p>
    </div>
  );
};

// function BrandPostRequirements({ challenge }: { challenge: Challenge }) {

//   return (
//     <div className="flex flex-col space-y-3 pt-2">
//       {challenge?.postRequirements?.map((brandRequirement) => {
//         return (
//           <div
//             key={brandRequirement?.id}
//             className="flex flex-row items-center space-x-2"
//           >
//             <p className="text-right text-xs text-black">
//               {brandRequirement?.emoji}
//             </p>
//             <p className="w-full flex-1 text-xs text-gray-800">
//               {formatRequirement(brandRequirement?.requirement)}
//             </p>
//           </div>
//         );
//       })}
//     </div>
//   );
// }
