import { useMutation, useQuery } from '@apollo/client';
import RequirementsTable from '../components/shared/RequirementsTable';
import { POST_REQUIREMENTS } from '../graphql/queries';
import { PostRequirement } from '../__generated__/graphql';
import { ADD_POST_REQUIREMENT } from '../graphql/mutations';

export default function ChallengeRequirementsPage() {
  const { data, loading } = useQuery(POST_REQUIREMENTS);

  if (loading) return <p>Loading...</p>;
  return (
    <div className="mb-4 mt-4 space-y-6 px-4 sm:px-6 lg:px-8">
      {/* Header */}
      <div className="space-y-2">
        <h1 className="text-xl font-semibold text-gray-900">
          Challenge Requirements
        </h1>
        <p className="text-sm text-gray-700">
          A list of all the challenge requirements
        </p>
      </div>

      {/* Challenge Requirements */}
      <RequirementsTable
        showPreview={false}
        hiddenTypes={new Set(['HASHTAG', 'MENTION'])}
        // Reallow this
        allowEditing={false}
        currentActiveRequirements={data?.postRequirements as PostRequirement[]}
        onAddRequirement={() => {}}
      />
    </div>
  );
}
